
const SNCRX = {
	onReady: () => {
		SNCRX.selector = '.sncr-validate-pristine';
		const forms = document.querySelectorAll(SNCRX.selector);

		if( forms.length < 1 )
			return;


		SNCRX.initSpamProtection();
		SNCRX.initPristine();

		forms.forEach( (form) => {
			form.addEventListener('submit', SNCRX.initSubmit);
		} );
	},



	initSpamProtection: function(){
		let forms = document.querySelectorAll(SNCRX.selector);

		forms.forEach( (form) => {
			let input = document.createElement('input');
			input.setAttribute('type', 'hidden')
			input.setAttribute('name', 'sn-form-start');
			input.setAttribute('value', Date.now());

			form.prepend(input);
		});
		
	},



	initPristine: function(){
		let lang = document.documentElement.lang.slice(0,2) || 'en';

		Pristine.setLocale(lang);
		Pristine.addMessages(lang, localizeJS.pristineLang);
		
		SNCRX.pristineConfig = {
			classTo: 'control',
        	errorClass: 'is-danger',
        	successClass: false,
        	errorTextParent: 'control',
        	errorTextClass: 'help tag is-danger'
		};

		const forms = document.querySelectorAll(SNCRX.selector);
		
		forms.forEach( (el) => {
			new Pristine(el, SNCRX.pristineConfig);
		});
	},



	initSubmit: function(){
		SNCRX.event 	= event;
		SNCRX.context 	= SNCRX.event.target;
		SNCRX.formname 	= jQuery('input[name=sn-form-name]', SNCRX.context).val();
      
        SNCRX.event.preventDefault();

        let pristine = new Pristine(SNCRX.context, SNCRX.pristineConfig);
        
        if ( pristine.validate() ) {
    		SNCRX.context.querySelector('#sn-submit').classList.add('is-loading');
    		SNCRX.sendForm();
    	}
    },



    sendForm: function(){
		const { action, method } = SNCRX.context;
		const body = new FormData(SNCRX.context);

		fetch(action, {
			method,
			body,
			credentials: "same-origin",
		})
		.then((response) => response.json())
		.then((response) => {
			console.log(response);

			SNCRX.context.parentElement.querySelectorAll('.notification').forEach((el) => {
				el.classList.add('is-hidden');
			});
			

			if ( response == 'success' ) {
				SNCRX.context.parentElement.querySelectorAll('.notification.is-success').forEach((el) => {
					el.classList.remove('is-hidden');
				});
				
				SNCRX.context.classList.add('is-hidden');
			
			} else {
            	SNCRX.context.parentElement.querySelectorAll('.notification.is-danger').forEach((el) => {
                    el.innerHTML = response.message;
					el.classList.remove('is-hidden');
				});

			}
			
			SNCRX.context.querySelector('#sn-submit').classList.remove('is-loading');
			SNCRX.context.scrollIntoView( {behavior: 'smooth'} );
		})
		.catch((error) => {
				console.log('Server error: ' + error);
				
				SNCRX.context.parentElement.querySelectorAll('.notification').forEach((el) => {
					el.classList.add('is-hidden');
				});

				// SNCRX.context.classList.add('is-hidden');

				SNCRX.context.parentElement.querySelectorAll('.notification.is-danger').forEach((el) => {
					el.classList.remove('is-hidden');
				});

				SNCRX.context.querySelector('#sn-submit').classList.remove('is-loading');
				SNCRX.context.scrollIntoView( {behavior: 'smooth'} );
			})
		;
    }
}


SNCRX.onReady();